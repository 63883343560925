import React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../../components/layout"
// import Image from "../components/image"
import SEO from "../../components/seo"
import Img from "gatsby-image"

import { Container, Row, Col, Card } from "react-bootstrap"


const IndexPage = (props, {data}) => (
  <Layout>
    <SEO title="Special Guests We've Interviewed in the Cook Islands" />
    <h1 className="text-center">Special Guests</h1>
    
        <Container>

          
          
          <Row>
          {props.data.latestEpisodes.edges.map(({ node }) => (
            <Col lg={4} xs={12} key={node.id}>
              
                <Card className="mb-4">
                  <Link to={node.fields.slug} className="card-link">
                    <Img 
                      fluid={node.frontmatter.Image01.childImageSharp.fluid} 
                      alt={node.frontmatter.title + " Cover"}
                      className="card-img-top"
                      fadeIn
                    />
                    </Link>
                
                  <Card.Body>
                    <h2><Link to={node.fields.slug} className="card-link">{node.frontmatter.title}</Link></h2>
                    {/* <p>{node.frontmatter.recap}</p> */}
                    {/* <Card.Text>
                    <p>{node.excerpt}</p>
                    </Card.Text> */}
                    {/* <Button variant="primary">Go somewhere</Button> */}
                  </Card.Body>
                  {/* <Card.Footer className="text-muted">
                    <Link to={"/explore/" + node.frontmatter.categorySlug}>
                    {node.frontmatter.category}
                    </Link></Card.Footer> */}
                </Card>
            </Col>
            ))}
            </Row>
        </Container>
  </Layout>
)

export default IndexPage

export const pageQuery = graphql`
  query {
    indexImage: file(relativePath: { eq: "header-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 50) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    podcastImage: file(relativePath: { eq: "podcast-cover-image-01-smaller.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    photoOfHaydnChantal: file(relativePath: { eq: "chantal-and-haydn-circle.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 60) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    latestEpisodes: allMarkdownRemark(
      sort: {fields: frontmatter___episode, order: DESC}, 
      filter: {frontmatter: {categorySlug: {eq: "special-guests/"}}
      }) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            episode
            category
            categorySlug
            recap
            Image01 {
              childImageSharp {
                fluid(maxWidth: 600, maxHeight: 300, cropFocus: CENTER) {
                  base64
                tracedSVG
                aspectRatio
                srcWebp
                srcSetWebp
                originalName
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`;